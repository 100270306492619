import React from "react";
import {
  Box,
  Typography,
  IconButton,
  Badge,
  Slide,
  Grid,
  InputBase,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { ArrowForwardIos as ArrowForwardIcon } from "@mui/icons-material";
import { Avatar, MessageModel } from "@chatscope/chat-ui-kit-react";
import { Contact } from "@/domain/chat.interface";
import { useTheme } from "@mui/material/styles";

interface ContactListProps {
  contacts: Contact[];
  onSelectContact: (contactId: string) => void;
  contactMessages: { [key: string]: MessageModel[] };
  selectedContactId: string | null;
  isMobile?: boolean;
  visible?: boolean;
}

const ContactList: React.FC<ContactListProps> = ({
  contacts,
  onSelectContact,
  contactMessages,
  selectedContactId,
  isMobile = false,
  visible = true,
}) => {
  const theme = useTheme();

  return (
    <Slide
      direction="left"
      in={visible || !isMobile}
      mountOnEnter
      unmountOnExit
      timeout={250}
    >
      <Grid item xs={12} md={5}>
        <Box
          sx={{
            p: 2,
            backgroundColor: "white",
            borderRadius: 2,
            boxShadow: 1,
            maxHeight: "100%",
          }}
        >
          <Box
            sx={{
              backgroundColor: theme.palette.surface.light2,
              px: 2,
              width: {
                xs: "100%",
                sm: "100%",
              },
              height: "48px",
              mb: 2,
            }}
            className="flex rounded-lg py-3 max-w-full items-center justify-between h-14"
          >
            <InputBase
              placeholder="Search ..."
              inputProps={{ "aria-label": "search" }}
              onChange={() => {}}
              value={""}
            />
            <SearchIcon className="text-icon-primary" />
          </Box>
          {contacts.map((contact) => (
            <Box
              key={contact.id}
              onClick={() => onSelectContact(contact.id)}
              sx={{
                display: "flex",
                alignItems: "center",
                p: 1,
                cursor: "pointer",
                backgroundColor:
                  contact.id === selectedContactId
                    ? theme.palette.surface.light2
                    : "transparent",
                "&:hover": {
                  backgroundColor: theme.palette.surface.light2,
                },
                borderRadius: 1,
              }}
            >
              <Avatar
                style={{
                  backgroundColor: contact.unread
                    ? theme.palette.surface.main
                    : theme.palette.grey[400],
                  color: theme.palette.textMain.dark2,
                  width: "40px",
                  height: "40px",
                  lineHeight: "40px",
                  textAlign: "center",
                }}
              >
                {contact.name.charAt(0)}
              </Avatar>
              <Box sx={{ ml: 2, flexGrow: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{ mr: 1 }}
                    >
                      {contact.name}
                    </Typography>
                    <Badge
                      color="error"
                      variant="dot"
                      invisible={!contact.unread}
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    />
                  </Box>
                  <Typography variant="caption" color="textSecondary">
                    {contactMessages[contact.id]?.slice(-1)[0]?.sentTime}
                  </Typography>
                </Box>
                <Typography variant="body2" color="textSecondary">
                  {contact.lastMessage}
                </Typography>
              </Box>
              <IconButton sx={{ ml: 1 }}>
                <ArrowForwardIcon />
              </IconButton>
            </Box>
          ))}
        </Box>
      </Grid>
    </Slide>
  );
};

export default ContactList;
