export enum MODULE_NAME {
  GENERAL = "General",
  DASHBOARD = "Dashboard",
  USERS = "Users",
  PARTICIPANTS_LIST = "Participants list",
  PARTICIPANTS_MANAGE_OFFICERS = "Participant manage officers",
  PARTICIPANTS_MANAGE_SUPPORT_STAFF = "Participant manage support staff",
  PARTICIPANTS_MANAGE_INTERNAL_PROVIDER = "Participant manage internal providers",
  PARTICIPANT_GOALS = "Participant goals",
  PARTICIPANT_REWARDS = "Participant rewards",
  PARTICIPANT_JOURNALS = "Participant journals",
  SIGN_IN = "Sign in",
  SIGN_OUT = "Sign out",
}

export enum OPTIONS {
  AUDIT_LOG_VIEW = "Audit log view",
  EMAIL_MATCHING = "Email matching",
  TERMS_AND_CONDITIONS = "T&C",
  VERIFY_CODE = "Verify code",
  SESSION_START = "Session start",
  SIGN_OUT = "Sign out",
  CHANGE_LANGUAGE = "Change language",
  REQUEST_ACCOUNT_DELETION = "Request account deletion",
  USERS_GUIDE = "User’s guide",
  HOME = "Home",
  DASHBOARD = "Dashboard",
  PARTICIPANTS_LIST = "Participants list",
  PARTICIPANT_ADD = "Participant add",
  PARTICIPANT_PROFILE = "Participant profile",
  PARTICIPANT_EDIT = "Participant edit",
  PARTICIPANT_DISMISS = "Participant dismiss",
  MANAGE_OFFICERS = "Manage officers",
  MANAGE_SUPPORT_STAFF = "Manage support staff",
  MANAGE_INTERNAL_PROVIDERS = "Manage internal providers",
  MANAGE_CASE_PLAN = "Manage case plan",
  PARTICIPANT_GOALS_LIST = "Participant goals list",
  WEB_PART_GOAL_ADD = "When added new goal",
  GOAL_FROM_TEMPLATE_TO_PARTICIPANT = "Goal from template to participant",
  EDIT_GOAL_TO_PARTICIPANT = "Edit goal to participant",
  STEP_APPROVAL_TO_PARTICIPANT = "Step approval to participant",
  STEP_REJECTION_TO_PARTICIPANT = "Step rejection to participant",
  PARTICIPANT_JOURNALS_LIST = "Participant journals list",
  PARTICIPANT_JOURNAL = "Participant journal",
  PARTICIPANT_REWARDS_LIST = "Participant rewards list",
  PARTICIPANT_REWARD_REDEMPTION = "Participant reward redemption",
  PARTICIPANT_REWARD_VIEW_DETAIL_FROM_EXTERNAL_PROVIDER = "Participant reward view detail from external provider",
  ADD_INDIVIDUAL_APPOINTMENT = "Add individual appointment",
  EDIT_INDIVIDUAL_APPOINTMENT = "Edit individual appointment",
  ADD_GROUP_APPOINTMENT = "Add group appointment",
  EDIT_GROUP_APPOINTMENT = "Edit group appointment",
  DELETE_APPOINTMENT = "Delete appointment",
  MARK_AS_ABSENT = "Mark as absent",
  REVERT_ABSENCE = "Revert absence",
  LOG_ACTIVITY = "Log activity",
  LOG_WALK_IN_ACTIVITY = "Log walk-in activity",
  IN_HOUSE_SERVICE_ACTIVITY_DETAIL = "In-house service activity detail",
  USERS_LIST = "Users list",
  USER_ADD_EDIT = "User add / edit",
  DELETE = "Delete",
  RESEND_VERIFICATION_LINK = "Resend verification link",
}

export enum ACTION_CODE {
  MOB_SIGNUP_EMAIL_MATCH = "MOB_SIGNUP_EMAIL_MATCH",
  MOB_SIGNUP_TYC_OK = "MOB_SIGNUP_TYC_OK",
  MOB_SIGNUP_TYC_DECLINED = "MOB_SIGNUP_TYC_DECLINED",
  MOB_SIGNUP_ACCOUNT_COMPLETED = "",
  MOB_SIGNUP_RESEND_CODE = "MOB_SIGNUP_RESEND_CODE",
  MOB_SIGNIN_SUCCESSFULLY = "MOB_SIGNIN_SUCCESSFULLY",
  MOB_SETTING_CHN_LANG_ENTER = "MOB_SETTING_CHN_LANG_ENTER",
  MOB_SETTING_CHN_LANG_SUBMITTED = "MOB_SETTING_CHN_LANG_SUBMITTED",
  MOB_SETTING_DEL_ACCT_ENTER = "MOB_SETTING_DEL_ACCT_ENTER",
  MOB_SETTING_USER_GUIDE_ENTER = "MOB_SETTING_USER_GUIDE_ENTER",
  MOB_HOME_ENTER = "MOB_HOME_ENTER",
  WEB_GEN_LIST_AUDITLOG_VIEW = "WEB_GEN_LIST_AUDITLOG_VIEW",
  WEB_DASHBOARD_VIEW = "WEB_DASHBOARD_VIEW",
  WEB_PART_LIST_VIEW = "WEB_PART_LIST_VIEW",
  WEB_PART_LIST_SEARCH = "WEB_PART_LIST_SEARCH",
  WEB_PART_ADD_INVITE = "WEB_PART_ADD_INVITE",
  WEB_PART_PROFILE_VIEW = "WEB_PART_PROFILE_VIEW",
  WEB_PART_EDIT = "WEB_PART_EDIT",
  WEB_PART_DISMISS = "WEB_PART_DISMISS",
  WEB_PART_ADDED_OFFICERS = "WEB_PART_ADDED_OFFICERS",
  WEB_PART_REMOVED_OFFICERS = "WEB_PART_REMOVED_OFFICERS",
  WEB_PART_ADDED_STAFF = "WEB_PART_ADDED_STAFF",
  WEB_PART_REMOVED_STAFF = "WEB_PART_REMOVED_STAFF",
  WEB_PART_ADDED_INT_PROVIDERS = "WEB_PART_ADDED_INT_PROVIDERS",
  WEB_PART_REMOVED_INT_PROVIDERS = "WEB_PART_REMOVED_INT_PROVIDERS",
  WEB_PART_ADDED_CASE_PLAN = "WEB_PART_ADDED_CASE_PLAN",
  WEB_PART_GOAL_LIST_VIEW = "WEB_PART_GOAL_LIST_VIEW",
  WEB_PART_GOAL_ADDED_TEMPLATE = "WEB_PART_GOAL_ADDED_TEMPLATE",
  WEB_PART_GOAL_ADD = "WEB_PART_GOAL_ADD",
  WEB_PART_GOAL_EDIT = "WEB_PART_GOAL_EDIT",
  WEB_PART_GOAL_STEP_APPROVE = "WEB_PART_GOAL_STEP_APPROVE",
  WEB_PART_GOAL_STEP_REJECT = "WEB_PART_GOAL_STEP_REJECT",
  WEB_PART_JOURNAL_LIST_VIEW = "WEB_PART_JOURNAL_LIST_VIEW",
  WEB_PART_JOURNAL_VIEW = "WEB_PART_JOURNAL_VIEW",
  WEB_PART_REWARDS_VIEW = "WEB_PART_REWARDS_VIEW",
  WEB_PART_REWARD_REDEMPTION_APPROVE = "WEB_PART_REWARD_REDEMPTION_APPROVE",
  WEB_PART_REWARD_REDEMPTION_REJECT = "WEB_PART_REWARD_REDEMPTION_REJECT",
  WEB_PART_REWARD_EXT_PROV_DETAIL_VIEW = "WEB_PART_REWARD_EXT_PROV_DETAIL_VIEW",
  WEB_APPT_IND_ADD = "WEB_APPT_IND_ADD",
  WEB_APPT_IND_EDIT = "WEB_APPT_IND_EDIT",
  WEB_APPT_GRP_ADD = "WEB_APPT_GRP_ADD",
  WEB_APPT_DELETE = "WEB_APPT_DELETE",
  WEB_APPT_GRP_REMOVED = "WEB_APPT_GRP_REMOVED",
  WEB_APPT_MARK_ABSENT = "WEB_APPT_MARK_ABSENT",
  WEB_APPT_REVERT_ABSENCE = "WEB_APPT_REVERT_ABSENCE",
  WEB_APPT_LOG_ACTIVITY = "WEB_APPT_LOG_ACTIVITY",
  WEB_INHSE_LOG_WALKIN_ACTIVITY = "WEB_INHSE_LOG_WALKIN_ACTIVITY",
  WEB_INHSE_IN_HOUSE_VIEW = "WEB_INHSE_IN_HOUSE_VIEW",
  WEB_USRS_LIST_VIEW = "WEB_USRS_LIST_VIEW",
  WEB_USRS_LIST_SEARCH = "WEB_USRS_LIST_SEARCH",
  WEB_USRS_USR_ADD_INVITE = "WEB_USRS_USR_ADD_INVITE",
  WEB_USRS_USR_VIEW = "WEB_USRS_USR_VIEW",
  WEB_USRS_USR_EDIT = "WEB_USRS_USR_EDIT",
  WEB_USRS_USR_DELETE = "WEB_USRS_USR_DELETE",
  WEB_USRS_USR_RESEND_LINK = "WEB_USRS_USR_RESEND_LINK",
  WEB_SIGN_OUT_OK = "WEB_SIGN_OUT_OK",
  WEB_SIGN_OUT_TIMEOUT = "WEB_SIGN_OUT_TIMEOUT",
}
