import { axios, transformAxiosError } from "../utils/axios.create";
import {
  CreateClientPayload,
  CreateClientResponse,
  earningsResponse,
  SearchClientsPayload,
  SearchClientsResponse,
  SearchEarningPayload,
  SearchEarningResponse,
} from "@/domain/client.interface";

const findAll = async ({
  sortField,
  sortOrder,
  pageNumber,
  pageSize,
  search,
  status,
  programId,
  userId,
}: SearchClientsPayload): Promise<SearchClientsResponse> => {
  if (!programId) {
    return {
      participants: [],
      totalCount: 0,
    };
  }

  const queryParams = new URLSearchParams({
    status: status.join(","),
    search,
    pageNumber: String(pageNumber + 1),
    pageSize: String(pageSize),
    sortField,
    sortOrder: sortOrder === 1 ? "asc" : "desc",
    programId,
    userId,
  });

  return axios
    .get(`/participants/search?${queryParams.toString()}`)
    .then((res) => {
      const body = res.data.body;
      return {
        participants: body.participants,
        totalCount: body.totalCount,
      };
    })
    .catch((err) => {
      return Promise.reject(transformAxiosError(err));
    });
};

const create = async (
  data: CreateClientPayload
): Promise<CreateClientResponse> => {
  return axios
    .post("/participants", data)
    .then((res) => res.data.body)
    .catch((err) => Promise.reject(transformAxiosError(err)));
};

const update = async ({
  participantId,
  enrollmentId,
  data,
}: {
  participantId: string;
  enrollmentId: string;
  data: CreateClientPayload;
}): Promise<CreateClientResponse> => {
  return axios
    .put(`/participants/${participantId}/enrollment/${enrollmentId}`, data)
    .then((res) => res.data.body)
    .catch((err) => Promise.reject(transformAxiosError(err)));
};

const dismissed = async ({
  participantId,
  enrollmentId,
  updatedBy,
}: {
  participantId: string;
  enrollmentId: string;
  updatedBy: string;
}): Promise<void> => {
  return axios
    .put(
      `/participants/${participantId}/enrollment/disenroll/${enrollmentId}`,
      {
        updatedBy,
      }
    )
    .then((res) => res.data.body)
    .catch((err) => Promise.reject(transformAxiosError(err)));
};

const findAllEarnings = async ({
  sortField,
  sortOrder,
  pageNumber,
  pageSize,
  search,
  status,
  programId,
  enrollmentId,
}: SearchEarningPayload): Promise<SearchEarningResponse> => {
  const queryParams = new URLSearchParams({
    search,
    pageNumber: String(pageNumber + 1),
    pageSize: String(pageSize),
    sortField,
    sortOrder: sortOrder === 1 ? "asc" : "desc",
    programId,
    enrollmentId,
    mechanism: status.join(","),
  });

  return axios
    .get(
      `/enrollments/${enrollmentId}/rewards-earned/search?${queryParams.toString()}`
    )
    .then((res) => {
      const body = res.data.body;
      return {
        totalCount: body.totalCount,
        earnings: body.list.map((item: earningsResponse) => {
          return {
            description: item.description,
            mechanism: mapMechanismToReadable(item.mechanism),
            date: item.date,
            points: item.points,
          };
        }),
      };
    })
    .catch((err) => {
      return Promise.reject(transformAxiosError(err));
    });
};

export const ClientsService = {
  findAll,
  create,
  update,
  dismissed,
  findAllEarnings,
};

const mapMechanismToReadable = (mechanism: string): string => {
  const labelMap: Record<string, string> = {
    GOAL_COMPLETION: "Goal completions",
    STEP_COMPLETION: "Step completions",
    JOURNALS: "Journals",
    SUPERVISION_ACTIVITIES: "Supervision activities",
    IN_HOUSE_SERVICES: "In-house services",
    EXTERNAL_PROVIDER_SERVICES: "External provider services",
  };

  return labelMap[mechanism] || mechanism;
};
