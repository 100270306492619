/* istanbul ignore file  */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Snackbar } from "@mui/material";
import { useParticipantById } from "@/hooks/useParticipants";
import { copyToClipboard } from "@/utils/copyToClipboard";
import { usePageStore } from "@/hooks/usePageStore";
import {
  useAuditLogFirstRender,
  useCreateAuditlog,
} from "@/hooks/useAuditLogs";
import { ACTION_CODE, MODULE_NAME, OPTIONS } from "@/domain/auditlog.enum";
import { useUserStore } from "@/hooks/useUserStore";
import OfficersModal from "@/ui/modals/clients/OfficersModal";
import ProviderModulesModal from "@/ui/modals/clients/ProviderModulesModal";
import ProfileBreadcrumb from "@/ui/breadcrumbs/clients/ProfileBreadcrumb";
import ProfileCards from "@/ui/content/clients/ProfileCards";
import { ParticipantResponse } from "@/domain/participant.interface";
import { RoleSubType } from "@/domain/enrollment.interface";
import ClientEditModal from "@/ui/modals/clients/ClientEditModal";
import GenericAlertModal from "@/components/AlertModal";
import { useAppTheme } from "@/utils/theme";
import { useDismissedClient } from "@/hooks/useClients";
import { ErrorResponse } from "@/domain/error.interface";
import { useFeedBack } from "@/providers/FeedBackProvider/FeedBackContext";
import ActionCards from "@/ui/content/clients/ActionCards";
import {
  useGetEnrollmentBalance,
  useSearchJournals,
} from "@/hooks/useEnrollments";
import { useGetAlertsByOfficerId } from "@/hooks/useAlerts";

const ClientProfile: React.FC = () => {
  const { t } = useTranslation("clients");
  const navigate = useNavigate();
  const theme = useAppTheme();
  const { userInfo, modules, fetchModules } = useUserStore();
  const { participantId } = useParams();
  const { showSnackBar } = useFeedBack();
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { setPageTitle } = usePageStore();
  const {
    data: participantResponse,
    isLoadingParticipant,
    refetchParticipant,
  } = useParticipantById(participantId || "");

  const { enrollmentBalance } = useGetEnrollmentBalance(
    participantResponse?.lastEnrollment?._id ?? "",
    !!participantResponse?.lastEnrollment
  );
  const { journals } = useSearchJournals({
    sortField: "createdAt",
    sortOrder: -1,
    pageNumber: 0,
    pageSize: 200,
    enrollmentId: participantResponse?.lastEnrollment?._id ?? "",
  });

  const { alerts, isLoadingAlerts } = useGetAlertsByOfficerId(
    userInfo?._id || "",
    100,
    1
  );

  const createAuditLog = useCreateAuditlog();
  const dismissClient = useDismissedClient();

  const [genericAlert, setGenericAlert] = useState({
    open: false,
    title: "",
    message: "",
    onConfirm: () => {},
  });

  const [isOfficersModalOpen, setIsOfficersModalOpen] = useState(false);
  const [isSupportStaffModalOpen, setIsSupportStaffModalOpen] = useState(false);
  const [isInHouseProviderModalOpen, setIsInHouseProviderModalOpen] =
    useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleOpenOfficersModal = () => setIsOfficersModalOpen(true);
  const handleCloseOfficersModal = () => setIsOfficersModalOpen(false);

  const handleOpenSupportStaffModal = () => setIsSupportStaffModalOpen(true);
  const handleCloseSupportStaffModal = () => setIsSupportStaffModalOpen(false);

  const handleOpenInHouseProviderModal = () =>
    setIsInHouseProviderModalOpen(true);
  const handleCloseInHouseProviderModal = () =>
    setIsInHouseProviderModalOpen(false);

  const handleOpenEditModal = () => setIsEditModalOpen(true);
  const handleCloseEditModal = () => setIsEditModalOpen(false);

  const handleOpenDismissModal = () => {
    setGenericAlert({
      open: true,
      title: "Dismiss",
      message:
        "Are you sure you want to dismiss this client? This action cannot be undone.",
      onConfirm: handleDismissClient,
    });
  };
  const handleCloseDismissModal = () => {
    setGenericAlert({
      open: false,
      title: "",
      message: "",
      onConfirm: () => {},
    });

    navigate("/participants");
  };

  const handleDismissClient = async () => {
    try {
      await dismissClient.mutateAsync(
        {
          participantId: participantResponse?.participant._id as string,
          enrollmentId: participantResponse?.lastEnrollment?._id as string,
          updatedBy: userInfo?._id as string,
        },
        {
          onSuccess: () => {
            showSnackBar(
              "The client has been dismissed and the user has been disabled.",
              "success"
            );
            refetchParticipant();
            handleCloseDismissModal();

            createAuditLog.mutate({
              appType: "WEB_BACK_OFFICE",
              module: MODULE_NAME.PARTICIPANTS_LIST,
              option: OPTIONS.PARTICIPANT_DISMISS,
              actionCode: ACTION_CODE.WEB_PART_DISMISS,
              action: "When dimissing client",
              detail: `Dismissed client ${participantResponse?.participant.firstName} ${participantResponse?.participant.lastName} from the program`,
              transactionDate: new Date(),
              accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
              createdBy: userInfo?._id as string,
              targetId: participantResponse?.participant._id as string,
            });
          },
          onError: (error: ErrorResponse) => {
            showSnackBar(
              (Array.isArray(error.message)
                ? error.message[0]
                : error.message) ||
                error.error ||
                "An error occurred"
            );
          },
        }
      );
    } catch (error: unknown) {
      const err = error as ErrorResponse;
      showSnackBar(
        (Array.isArray(err.message) ? err.message[0] : err.message) ||
          err.error ||
          "An error occurred"
      );
    }
  };

  const handleCopy = (text: string, message: string) => {
    copyToClipboard(text);
    setSnackbarMessage(message);
    setIsSnackbarOpen(true);
  };

  const handleSnackbarClose = () => setIsSnackbarOpen(false);

  useAuditLogFirstRender({
    appType: "WEB_BACK_OFFICE",
    module: MODULE_NAME.PARTICIPANTS_LIST,
    option: OPTIONS.PARTICIPANT_PROFILE,
    actionCode: ACTION_CODE.WEB_PART_PROFILE_VIEW,
    action: "When entering",
    detail: participantResponse?.participant
      ? `Viewed the client profile ${participantResponse.participant.firstName} ${participantResponse.participant.lastName}`
      : "",
    transactionDate: new Date(),
    accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
    createdBy: userInfo?._id as string,
    targetId: participantResponse?.participant._id as string,
  });

  useEffect(() => {
    if (participantResponse?.participant?.firstName) {
      setPageTitle(t("client_profile.page_title"));
    }
  }, []);

  useEffect(() => {
    const primaryOfficerId =
      participantResponse?.lastEnrollment?.primaryOfficerId;
    const additionalOfficerIds =
      participantResponse?.lastEnrollment?.additionalOfficerId || [];
    const userId = userInfo?._id;

    if (primaryOfficerId && userId) {
      if (
        primaryOfficerId !== userId &&
        !additionalOfficerIds.includes(userId)
      ) {
        navigate("/403");
      }
    }
  }, [
    participantResponse?.lastEnrollment?.primaryOfficerId,
    participantResponse?.lastEnrollment?.additionalOfficerId,
    userInfo?._id,
  ]);

  useEffect(() => {
    if (!modules) {
      fetchModules();
    }
  }, [modules, fetchModules]);

  const createAuditLogAddStaff = async (
    staffNames: string[],
    roleType: RoleSubType,
    participantId?: string
  ) => {
    try {
      const staffString = staffNames.join(", ");
      const rolePlural = staffNames.length > 1 ? "s" : "";
      const auditLogDetails =
        roleType === RoleSubType.SUPPORT_STAFF
          ? {
              module: MODULE_NAME.PARTICIPANTS_MANAGE_SUPPORT_STAFF,
              option: OPTIONS.MANAGE_SUPPORT_STAFF,
              actionCode: ACTION_CODE.WEB_PART_ADDED_STAFF,
              action: "When added new staff",
              detail: `Assigned support staff${rolePlural} ${staffString} to ${participantResponse?.participant.firstName} ${participantResponse?.participant.lastName}`,
            }
          : {
              module: MODULE_NAME.PARTICIPANTS_MANAGE_INTERNAL_PROVIDER,
              option: OPTIONS.MANAGE_INTERNAL_PROVIDERS,
              actionCode: ACTION_CODE.WEB_PART_ADDED_INT_PROVIDERS,
              action: "When added new internal providers",
              detail: `Assigned internal provider${rolePlural} ${staffString} to ${participantResponse?.participant.firstName} ${participantResponse?.participant.lastName}`,
            };

      await createAuditLog.mutateAsync({
        appType: "WEB_BACK_OFFICE",
        module: auditLogDetails.module,
        option: auditLogDetails.option,
        actionCode: auditLogDetails.actionCode,
        action: auditLogDetails.action,
        detail: auditLogDetails.detail,
        transactionDate: new Date(),
        accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
        createdBy: userInfo?._id as string,
        targetId: participantId,
      });
    } catch (error) {
      showSnackBar(
        `An error occurred while adding staff auditlog: ${error}`,
        "error"
      );
    }
  };

  const createAuditLogRemoveStaff = async (
    staffName: string,
    roleType: RoleSubType,
    participantId?: string
  ) => {
    const auditLogDetails =
      roleType === RoleSubType.SUPPORT_STAFF
        ? {
            module: MODULE_NAME.PARTICIPANTS_MANAGE_SUPPORT_STAFF,
            option: OPTIONS.MANAGE_SUPPORT_STAFF,
            actionCode: ACTION_CODE.WEB_PART_REMOVED_STAFF,
            action: "When support staff removed",
            detail: `Removed support staff ${staffName} from ${participantResponse?.participant.firstName} ${participantResponse?.participant.lastName}`,
          }
        : {
            module: MODULE_NAME.PARTICIPANTS_MANAGE_INTERNAL_PROVIDER,
            option: OPTIONS.MANAGE_INTERNAL_PROVIDERS,
            actionCode: ACTION_CODE.WEB_PART_REMOVED_INT_PROVIDERS,
            action: "When internal providers removed",
            detail: `Removed internal provider ${staffName} from ${participantResponse?.participant.firstName} ${participantResponse?.participant.lastName}`,
          };

    await createAuditLog.mutateAsync({
      appType: "WEB_BACK_OFFICE",
      module: auditLogDetails.module,
      option: auditLogDetails.option,
      actionCode: auditLogDetails.actionCode,
      action: auditLogDetails.action,
      detail: auditLogDetails.detail,
      transactionDate: new Date(),
      accountId: `${process.env.REACT_APP_ACCOUNT_ID}`,
      createdBy: userInfo?._id as string,
      targetId: participantId,
    });
  };

  return (
    <Box>
      <ProfileBreadcrumb />

      <ProfileCards
        participantResponse={participantResponse || ({} as ParticipantResponse)}
        isLoadingParticipant={isLoadingParticipant}
        handleCopy={handleCopy}
        handleOpenOfficersModal={handleOpenOfficersModal}
        handleOpenSupportStaffModal={handleOpenSupportStaffModal}
        handleOpenInHouseProviderModal={handleOpenInHouseProviderModal}
        handleOpenEditModal={handleOpenEditModal}
        handleOpenDismissModal={handleOpenDismissModal}
      />
      {participantResponse && enrollmentBalance && !isLoadingAlerts && (
        <ActionCards
          participantInfo={participantResponse}
          enrollmentBalance={enrollmentBalance}
          alerts={alerts}
          searchJournalResponse={journals}
        />
      )}

      <OfficersModal
        participantResponse={participantResponse || ({} as ParticipantResponse)}
        open={isOfficersModalOpen}
        onClose={handleCloseOfficersModal}
        refetchParticipant={refetchParticipant}
      />

      <ProviderModulesModal
        roleSubType={RoleSubType.SUPPORT_STAFF}
        participantResponse={participantResponse || ({} as ParticipantResponse)}
        open={isSupportStaffModalOpen}
        onClose={handleCloseSupportStaffModal}
        refetchParticipant={refetchParticipant}
        modules={modules || []}
        isLoadingParticipant={isLoadingParticipant}
        providersList={participantResponse?.lastEnrollment?.supportStaffs || []}
        createAuditLogAddStaff={(staffName, participantId) =>
          createAuditLogAddStaff(
            staffName,
            RoleSubType.SUPPORT_STAFF,
            participantId
          )
        }
        createAuditLogRemoveStaff={(staffName, participantId) =>
          createAuditLogRemoveStaff(
            staffName,
            RoleSubType.SUPPORT_STAFF,
            participantId
          )
        }
      />

      <ProviderModulesModal
        roleSubType={RoleSubType.INTERNAL_PROVIDER}
        participantResponse={participantResponse || ({} as ParticipantResponse)}
        open={isInHouseProviderModalOpen}
        onClose={handleCloseInHouseProviderModal}
        refetchParticipant={refetchParticipant}
        modules={modules || []}
        isLoadingParticipant={isLoadingParticipant}
        providersList={
          participantResponse?.lastEnrollment?.internalProviders || []
        }
        createAuditLogAddStaff={(staffName, participantId) =>
          createAuditLogAddStaff(
            staffName,
            RoleSubType.INTERNAL_PROVIDER,
            participantId
          )
        }
        createAuditLogRemoveStaff={(staffName, participantId) =>
          createAuditLogRemoveStaff(
            staffName,
            RoleSubType.INTERNAL_PROVIDER,
            participantId
          )
        }
      />

      {isEditModalOpen ? (
        <ClientEditModal
          open={isEditModalOpen}
          onClose={handleCloseEditModal}
          participant={participantResponse || ({} as ParticipantResponse)}
          refetchParticipant={refetchParticipant}
        />
      ) : null}

      {genericAlert.open ? (
        <GenericAlertModal
          onCancel={() =>
            setGenericAlert((prevState) => ({ ...prevState, open: false }))
          }
          onClick={() => {
            genericAlert.onConfirm();
          }}
          title={genericAlert.title}
          description={genericAlert.message}
          submitText="Confirm"
          cancelText="Cancel"
          theme={theme}
        />
      ) : null}

      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default ClientProfile;
