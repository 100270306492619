import React, { useState } from "react";
import Modal from "@/components/Modal/index";
import { useAppTheme } from "@/utils/theme";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import BasicDatepicker from "@/components/Datepicker";
import dayjs, { Dayjs } from "dayjs";
import { headCellsConfig } from "@/utils/tableConfig";
import DataTable, { DataTableCell, HeadCell } from "@/components/DataTable";
import { ReactNode, useEffect, useMemo } from "react";
import { useGetAuditLog } from "@/hooks/useAuditLogs";
import { useGetSettings } from "@/hooks/useSetting";
import GenericAlertModal from "@/components/AlertModal";
import { MODULE_NAME } from "@/domain/auditlog.enum";
import { useTranslation } from "react-i18next";

const createData = (
  id: number,
  timestamp: ReactNode,
  user: ReactNode,
  eventDescription: ReactNode
): DataTableCell => {
  return {
    id,
    timestamp,
    user,
    eventDescription,
  };
};

interface AuditLogModalProps {
  open: boolean;
  module: MODULE_NAME;
  onClose: () => void;
}

type Ordering = {
  pageNumber: number;
  pageSize: number;
  sortOrder: number;
  sortField: string;
};

export default function AuditLogModal({
  open,
  module,
  onClose,
}: AuditLogModalProps) {
  const theme = useAppTheme();
  const { t } = useTranslation("auditLogs");
  const [dateRange, setDateRange] = useState({
    from: dayjs().subtract(1, "day").startOf("day"),
    to: dayjs().endOf("day"),
  });
  const { data: settings, isPending: areSettingsPending } = useGetSettings();

  useEffect(() => {
    setDateRange({
      ...dateRange,
      from: dayjs()
        .subtract((settings?.defaultDaysAuditLog as number) || 1, "day")
        .startOf("day"),
    });
  }, [settings]);

  const onRefresh = () => {
    setDateRange({
      from: dayjs()
        .subtract((settings?.defaultDaysAuditLog as number) || 1, "day")
        .startOf("day"),
      to: dayjs().endOf("day"),
    });
  };

  const [dateRangeToInvalid, setDateRangeToInvalid] = useState(false);
  const [openInvalidRange, setOpenInvalidRange] = useState(false);
  const invalidMessage = t("invalid_date_range_message");

  const orderingState = useState<Ordering>({
    pageNumber: 0,
    pageSize: 10,
    sortOrder: -1,
    sortField: "timestamp",
  });

  const [sortOrdering, setSortOrdering] = orderingState;

  const {
    data: dataAudit,
    isLoading,
    refetch: refetchGetAuditLogs,
  } = useGetAuditLog(
    module,
    dateRange.from.toISOString(),
    dateRange.to.toISOString(),
    sortOrdering.pageNumber,
    sortOrdering.pageSize,
    sortOrdering.sortField,
    sortOrdering.sortOrder
  );

  const headCells: HeadCell[] = headCellsConfig.auditLog;

  const rows = useMemo(() => {
    if (!dataAudit) {
      return [];
    }

    return dataAudit.data.map(
      (
        row: {
          timestamp: string;
          user: ReactNode;
          eventDescription: ReactNode;
        },
        index: number
      ) => {
        return createData(
          index,
          <div style={{ minWidth: "210px" }}>
            {dayjs(row.timestamp).format(
              (settings?.generalDateFormat as string) || "DD MMM YYYY, hh:MM A"
            )}
          </div>,
          <div style={{ minWidth: "180px" }}>{row.user}</div>,
          <div style={{ minWidth: "350px" }}>{row.eventDescription}</div>
        );
      }
    );
  }, [dataAudit]);

  useEffect(() => {
    refetchGetAuditLogs();
  }, [sortOrdering]);

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        isPending={false}
        title="Audit Log"
        theme={theme}
        size={{
          width: "100%",
          minHeight: 640,
          maxHeight: 800,
          maxWidth: 920,
        }}
        content={
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              padding: theme.spacing(2),
            }}
          >
            {!areSettingsPending && (
              <Box sx={{ display: "flex", gap: 1 }}>
                <BasicDatepicker
                  label="Date from"
                  onChange={
                    ((value: Dayjs) => {
                      if (
                        dateRange.to.endOf("day").diff(value.startOf("day")) > 0
                      ) {
                        setDateRange((prev) => ({
                          ...prev,
                          from: value?.startOf("day"),
                        }));

                        setSortOrdering((sortOrdering) => ({
                          ...sortOrdering,
                          pageNumber: 0,
                        }));

                        setDateRangeToInvalid(false);
                      } else {
                        setDateRangeToInvalid(true);
                        setOpenInvalidRange(true);
                      }
                    }) as (value: Dayjs | null) => void
                  }
                  value={dateRange.from}
                  error={dateRangeToInvalid}
                  sx={{ maxWidth: 220 }}
                  disableWrite={true}
                />
                <BasicDatepicker
                  label="Date to"
                  onChange={
                    ((value: Dayjs) => {
                      if (
                        value.endOf("day").diff(dateRange.from.startOf("day")) >
                        0
                      ) {
                        setDateRange((prev) => ({
                          ...prev,
                          to: value?.endOf("day"),
                        }));

                        setSortOrdering((sortOrdering) => ({
                          ...sortOrdering,
                          pageNumber: 0,
                        }));

                        setDateRangeToInvalid(false);
                      } else {
                        setDateRangeToInvalid(true);
                        setOpenInvalidRange(true);
                      }
                    }) as (value: Dayjs | null) => void
                  }
                  value={dateRange.to}
                  error={dateRangeToInvalid}
                  sx={{ maxWidth: 220 }}
                  minDate={dateRange.from}
                  disableWrite={true}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onRefresh}
                  sx={{
                    borderRadius: "8px",
                    width: 120,
                  }}
                >
                  Refresh
                </Button>
              </Box>
            )}

            <DataTable
              data={rows}
              headCells={headCells}
              order={sortOrdering.sortOrder === 1 ? "asc" : "desc"}
              orderBy={sortOrdering.sortField}
              rowsPerPage={sortOrdering.pageSize}
              page={sortOrdering.pageNumber}
              total={dataAudit?.total}
              isLoading={areSettingsPending || isLoading}
              onChangePage={(event, newPage) => {
                setSortOrdering((sortOrdering) => ({
                  ...sortOrdering,
                  pageNumber: newPage,
                }));
              }}
              onChangeSize={(event) => {
                setSortOrdering((sortOrdering) => ({
                  ...sortOrdering,
                  pageNumber: 0,
                  pageSize: Number(event.target.value),
                }));
              }}
              onChangeSort={(order, orderBy) => {
                setSortOrdering((sortOrdering) => ({
                  ...sortOrdering,
                  pageNumber: 0,
                  sortField: orderBy as string,
                  sortOrder: order === "asc" ? 1 : -1,
                }));
              }}
              messageEmptyData="No events found for the selected date range."
            />
          </Box>
        }
      />
      {openInvalidRange && (
        <GenericAlertModal
          title="Warning Selection"
          description={invalidMessage}
          submitText="Accept"
          onClick={() => {
            setOpenInvalidRange(false);
          }}
        />
      )}
    </>
  );
}
