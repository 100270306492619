import { Goal } from "./goal.interface";
import { UserType } from "./user.interface";

export enum RoleSubType {
  INTERNAL_PROVIDER = "IN-HOUSE_PROVIDER",
  SUPPORT_STAFF = "SUPPORT_STAFF",
}

export enum RedeemRequestStatus {
  PENDING_APPROVAL = "PENDING_APPROVAL",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export enum RedeemableItemType {
  TIME_REDUCTION = "TIME_REDUCTION",
  MONEY_REDUCTION = "MONEY_REDUCTION",
  REMOTE_APPOINTMENT = "REMOTE_APPOINTMENT",
}

export interface Enrollment {
  _id: string;
  isDeleted: boolean;
  createdBy: string;
  updatedBy: string;
  accountId: string;
  participantId: string;
  programId: string;
  orgUnitId: string;
  primaryOfficerId: string;
  additionalOfficerId: string[];
  status: "ACTIVE" | "INACTIVE" | "PENDING";
  enrollmentDate: string;
  cmStartDate: string;
  supportStaffs: ProviderModuleItem[];
  createdAt: string;
  updatedAt: string;
  internalProviders: ProviderModuleItem[];
  caseNumbers: string;
  externalId: string;
  primaryOfficer: UserType;
  additionalOfficersInfo: UserType[];
  supportStaffsInfo: UserType[];
  internalProvidersInfo: UserType[];
  goalIds: string[];
  goals: Goal[];
}

export interface ProviderModuleItem {
  providerId: string;
  modulesIds: string[];
}

export interface ProviderModule extends ProviderModuleItem {
  id: string;
  name: string;
}

export interface UpdateModulesPayload {
  enrollmentId: string;
  providerId: string;
  modulesIds: string[];
  providerType: string;
}

export interface UpdateEnrollmentPayload {
  enrollmentId: string;
  enrollmentData: Partial<Enrollment>;
}

export interface UpdateProviderModulesPayload {
  enrollmentId: string;
  providerId: string;
  modulesIds: string[];
}

export interface RedeemableItem {
  _id: string;
  accountId: string;
  type: RedeemableItemType;
  description: string;
  points: number;
  redeemableByQuantity: boolean;
  preUnitText: string;
  unit: string;
  postUnitText: string;
}

export interface RedeemRequest {
  _id: string;
  accountId: string;
  redeemableItemId: string;
  quantity: number;
  processDate: Date;
  parcaPoints: number;
  status: "PENDING_APPROVAL" | "APPROVED" | "REJECTED";
  approvedBy: string;
  approvedDate?: Date;
  rejectedBy?: string;
  rejectedDate?: Date;
  rejectedComment?: string;
  redeemableItem: RedeemableItem;
  rejectedByOfficer?: string;
}

export interface EnrollmentBalance {
  enrollmentId: string;
  balancePoints: number;
  totalEarnedPoints: number;
  totalRedeemedPoints: number;
}

export interface Image {
  _id: string;
  base64image: string;
  name: string;
  description: string;
}

export interface Journal {
  _id: string;
  accountId: string;
  enrollmentId: string;
  dateTime: Date;
  detail: string;
  emotionIcon: string;
  status: string;
  isViewed: boolean;
  emoticon: Image;
}

export type SearchRedeemRequestsPayload = {
  enrollmentId: string;
  sortField: string;
  sortOrder: number;
  pageNumber: number;
  pageSize: number;
  status: string[];
};

export interface SearchRedeemRequestsResponse {
  list: RedeemRequest[];
  totalCount: number;
}

export type SearchJournalsPayload = {
  enrollmentId: string;
  sortField: string;
  sortOrder: number;
  pageNumber: number;
  pageSize: number;
};

export interface SearchJournalResponse {
  list: Journal[];
  totalCount: number;
}
