import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl, {
  FormControlPropsSizeOverrides,
} from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import MuiSelect, {
  SelectChangeEvent,
  SelectProps,
} from "@mui/material/Select";
import { OverridableStringUnion } from "@mui/types";
import { Typography } from "@mui/material";

export interface BasicSelectProps {
  handleChange: (value: string | string[]) => void;
  data: Array<{ key: string; value: string; icon?: string }>;
  value: string | string[] | undefined;
  id: string;
  label?: string;
  multiple?: boolean;
  required?: boolean;
  error?: boolean;
  errorMessage?: string;
  isLoading?: boolean;
  displayEmpty?: boolean;
  size?: OverridableStringUnion<
    "small" | "medium",
    FormControlPropsSizeOverrides
  >;
  maxWidth?: number;
  showClearOption?: boolean;
  width?: number | string;
}

const Select: React.FC<SelectProps & BasicSelectProps> = (props) => {
  const {
    handleChange,
    data,
    value,
    id,
    label,
    multiple = false,
    required = false,
    error = false,
    errorMessage,
    isLoading = false,
    displayEmpty = false,
    size = "medium",
    maxWidth = "100%",
    showClearOption = false,
    width = "100%",
    ...otherProps
  } = props;
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  useEffect(() => {
    if (multiple && Array.isArray(value)) {
      const values = value.map(
        (key) => data.find((item) => item.key === key)?.value || ""
      );
      setSelectedValues(values);
    }
  }, [value, data, multiple]);

  const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string | string[];
    handleChange(
      multiple && typeof value === "object" ? value : (value as string)
    );

    if (multiple && Array.isArray(value)) {
      const values = value.map(
        (key) => data.find((item) => item.key === key)?.value || ""
      );
      setSelectedValues(values);
    }
  };

  const handleClear = (event: React.MouseEvent) => {
    event.stopPropagation();
    handleChange(multiple ? [] : "");
    setTimeout(() => {
      const element = document.activeElement as HTMLElement;
      if (element) {
        element.blur();
      }
    }, 0);
  };

  return (
    <Box
      sx={{
        minWidth: 180,
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        maxWidth: {
          xs: "100%",
          sm: "50%",
          md: maxWidth,
        },
        width,
      }}
    >
      <FormControl
        sx={{ height: size === "medium" ? "3.5rem" : "" }}
        size={size}
      >
        {!displayEmpty && (
          <InputLabel id={`${id}-label`} required={required} error={error}>
            {label}
          </InputLabel>
        )}
        <MuiSelect
          {...otherProps}
          labelId={`${id}-label`}
          id={id}
          value={value}
          label={label}
          onChange={handleSelectChange}
          multiple={multiple}
          required={required}
          placeholder="Select"
          disabled={isLoading || otherProps.disabled}
          displayEmpty={displayEmpty}
          error={error}
          {...(multiple
            ? { renderValue: () => selectedValues.join(", ") }
            : {})}
        >
          {showClearOption && (
            <MenuItem value="" onClick={handleClear}>
              <Typography style={{ fontStyle: "italic" }}>(Clear)</Typography>
            </MenuItem>
          )}
          {data.map((item) => (
            <MenuItem key={item.key} value={item.key}>
              {multiple ? (
                <>
                  <Checkbox
                    checked={Array.isArray(value) && value.includes(item.key)}
                  />
                  <ListItemText>
                    <Typography
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "180px",
                      }}
                    >
                      {item.value}
                    </Typography>
                  </ListItemText>
                </>
              ) : item.icon ? (
                <div className="flex flex-row content-center items-center">
                  <img
                    src={item.icon}
                    alt={item.value}
                    className="h-6 w-6 mr-2"
                  />
                  <ListItemText>
                    <Typography
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "180px",
                      }}
                    >
                      {item.value}
                    </Typography>
                  </ListItemText>
                </div>
              ) : (
                item.value
              )}
            </MenuItem>
          ))}
        </MuiSelect>
      </FormControl>
      {errorMessage && (
        <FormHelperText className="!ml-[14px]" error>
          {errorMessage}
        </FormHelperText>
      )}
    </Box>
  );
};

Select.displayName = "Select";

export default Select;
