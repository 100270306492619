import React from "react";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationHeader,
  Avatar,
  MessageModel,
} from "@chatscope/chat-ui-kit-react";
import { Typography, Slide, Grid, IconButton, Box } from "@mui/material";
import { Contact } from "@/domain/chat.interface";
import { useTheme } from "@mui/material/styles";
import { ArrowBackIos as ArrowBackwardIcon } from "@mui/icons-material";

interface ChatWindowProps {
  selectedContactData: Contact | null;
  contactMessages: MessageModel[];
  onSend: (message: string) => void;
  onGoBack: () => void;
  visible?: boolean;
  isMobile?: boolean;
}

const ChatWindow: React.FC<ChatWindowProps> = ({
  selectedContactData,
  contactMessages,
  onSend,
  onGoBack,
  visible = true,
  isMobile = false,
}) => {
  const theme = useTheme();

  return (
    <Slide
      direction="right"
      in={!!selectedContactData && visible}
      mountOnEnter
      unmountOnExit
      timeout={250}
    >
      <Grid item xs={12} md={7}>
        {selectedContactData ? (
          <MainContainer style={{ border: "none", background: "transparent" }}>
            <ChatContainer
              style={{
                background: "transparent",
                height: "calc(100vh - 122px)",
              }}
            >
              <ConversationHeader
                style={{
                  backgroundColor: theme.palette.surface.light2,
                  border: "none",
                  paddingTop: 0,
                }}
              >
                {isMobile ? (
                  <ConversationHeader.Back>
                    <IconButton onClick={onGoBack} sx={{ ml: 1 }}>
                      <ArrowBackwardIcon />
                    </IconButton>
                  </ConversationHeader.Back>
                ) : (
                  <Avatar
                    onClick={onGoBack}
                    style={{
                      background: theme.palette.surface.main,
                      color: theme.palette.textMain.dark2,
                      width: "40px",
                      height: "40px",
                      lineHeight: "40px",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    {selectedContactData.name.charAt(0)}
                  </Avatar>
                )}
                <ConversationHeader.Content
                  style={{
                    backgroundColor: theme.palette.surface.light2,
                  }}
                >
                  <Typography variant="h6" fontWeight="bold">
                    {selectedContactData.name}
                  </Typography>
                </ConversationHeader.Content>
              </ConversationHeader>
              <MessageList
                style={{
                  borderTopLeftRadius: 8,
                  borderTopRightRadius: 8,
                }}
              >
                {contactMessages.map((msg, index) => (
                  <Message
                    className="custom-message"
                    key={index}
                    model={{
                      sender: msg.sender,
                      direction: msg.direction,
                      position: msg.position,
                    }}
                    avatarPosition="tl"
                  >
                    {msg.direction === "incoming" && (
                      <Avatar
                        style={{
                          backgroundColor: theme.palette.surface.main,
                          width: 24,
                          height: 24,
                          minHeight: 24,
                          minWidth: 24,
                          lineHeight: "24px",
                          textAlign: "center",
                          fontSize: theme.typography.caption.fontSize,
                          fontWeight: theme.typography.fontWeightBold,
                        }}
                      >
                        {msg.sender?.charAt(0).toUpperCase()}
                      </Avatar>
                    )}
                    <Message.CustomContent>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          width: "100%",
                        }}
                      >
                        <Typography
                          variant="bodySmall"
                          sx={{ alignSelf: "flex-start" }}
                        >
                          <Message.HtmlContent html={msg.message} />
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "100%",
                          }}
                        >
                          <Typography
                            fontWeight={theme.typography.fontWeightBold}
                            variant="caption"
                          >
                            {msg.sentTime}
                          </Typography>
                        </Box>
                      </Box>
                    </Message.CustomContent>
                  </Message>
                ))}
              </MessageList>
              <MessageInput
                style={{
                  borderBottomLeftRadius: 8,
                  borderBottomRightRadius: 8,
                }}
                attachButton={false}
                placeholder="Type your message here..."
                onSend={onSend}
              />
            </ChatContainer>
          </MainContainer>
        ) : (
          <></>
        )}
      </Grid>
    </Slide>
  );
};

export default ChatWindow;
