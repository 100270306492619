import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SecurityIcon from "@mui/icons-material/SecurityOutlined";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ActionMenu from "@/components/ActionMenu";
import { useUserStore } from "@/hooks/useUserStore";
import { Theme } from "@mui/material";
import GenericAlertModal from "@/components/AlertModal";

interface ProfileMenuProps {
  handleOpenOfficersModal: () => void;
  handleOpenSupportStaffModal: () => void;
  handleOpenInHouseProviderModal: () => void;
  handleOpenDismissModal: () => void;
  theme: Theme;
}

const ProfileMenu: React.FC<ProfileMenuProps> = ({
  handleOpenOfficersModal,
  handleOpenSupportStaffModal,
  handleOpenInHouseProviderModal,
  handleOpenDismissModal,
  theme,
}) => {
  const { t } = useTranslation("clients");
  const { userHasAccessToCode } = useUserStore();
  const [genericAlert, setGenericAlert] = useState({
    open: false,
    title: "",
    message: "",
    onConfirm: () => {},
  });

  const handlePermissionAlert = () => {
    setGenericAlert({
      open: true,
      title: "Access Denied",
      message: t("client_profile.insufficient_permissions"),
      onConfirm: () =>
        setGenericAlert((prevState) => ({ ...prevState, open: false })),
    });
  };

  const actionMenuOptions = [
    {
      icon: <SecurityIcon />,
      value: t("client_profile.manage_officers"),
      onClick: userHasAccessToCode("PARTICIPANT_MANAGE_OFFICERS")
        ? handleOpenOfficersModal
        : handlePermissionAlert,
    },
    {
      icon: <ChatBubbleOutlineIcon />,
      value: t("client_profile.manage_support_staff"),
      onClick: userHasAccessToCode("PARTICIPANT_MANAGE_SUPPORT_STAFF")
        ? handleOpenSupportStaffModal
        : handlePermissionAlert,
    },
    {
      icon: <SentimentSatisfiedIcon />,
      value: t("client_profile.manage_in_house_providers"),
      onClick: userHasAccessToCode("PARTICIPANT_MANAGE_INT_PROVIDERS")
        ? handleOpenInHouseProviderModal
        : handlePermissionAlert,
    },
    {
      icon: <CancelOutlinedIcon />,
      value: "Dismiss Client",
      onClick: handleOpenDismissModal,
      sx: {
        color: theme.palette.error.main,
      },
    },
  ];

  return (
    <>
      <ActionMenu
        options={actionMenuOptions}
        id="profile-menu"
        data-testid="profile-menu"
        isSquare
        squareSx={{
          color: theme.palette.icon.dark,
          backgroundColor: theme.palette.menu.light2,
          "&:hover": {
            backgroundColor: theme.palette.surface.main,
          },
          borderRadius: "8px",
          width: "40px",
          height: "40px",
        }}
      />

      {genericAlert.open && (
        <GenericAlertModal
          onClick={() => genericAlert.onConfirm()}
          title={genericAlert.title}
          description={genericAlert.message}
          submitText={t("client_profile.confirm")}
          theme={theme}
        />
      )}
    </>
  );
};

export default ProfileMenu;
