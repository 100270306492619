import {
  Enrollment,
  EnrollmentBalance,
  Journal,
  RedeemRequest,
  RoleSubType,
  SearchJournalResponse,
  SearchJournalsPayload,
  SearchRedeemRequestsPayload,
  SearchRedeemRequestsResponse,
} from "@/domain/enrollment.interface";
import { CreateGoal } from "@/domain/goal.interface";
import { axios, transformAxiosError } from "@/utils/axios.create";

const findById = async (enrollmentId: string): Promise<Enrollment> => {
  return axios
    .get(`/enrollments/find/${enrollmentId}`)
    .then((res) => res.data.body)
    .catch((err) => {
      throw new Error(
        err.response?.data?.message || "Failed to fetch enrollment"
      );
    });
};

const updateEnrollment = async (
  enrollmentId: string,
  enrollmentData: Partial<Enrollment>
): Promise<Enrollment> => {
  return axios
    .put(`/enrollments/${enrollmentId}`, enrollmentData)
    .then((res) => res.data.body)
    .catch((err) => {
      throw new Error(
        err.response?.data?.message || "Failed to update enrollment"
      );
    });
};

const updateSupportStaffModules = async (
  enrollmentId: string,
  providerId: string,
  modulesIds: string[],
  providerType: string
): Promise<void> => {
  return axios
    .put(
      `/enrollments/${enrollmentId}/provider-modules/${providerId}?providerType=${providerType}`,
      {
        modulesIds,
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(
        error.response?.data?.message || "Error updating modules"
      );
    });
};

const updateProviderModules = async (
  enrollmentId: string,
  providerId: string,
  modulesIds: string[]
): Promise<void> => {
  return axios
    .post(`/enrollments/${enrollmentId}/provider-modules`, {
      providerId,
      modulesIds,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(
        error.response?.data?.message || "Failed to post provider modules"
      );
    });
};

const removeSupportStaff = async (
  enrollmentId: string,
  providerId: string,
  providerType: RoleSubType
): Promise<void> => {
  return axios
    .delete(
      `/enrollments/${enrollmentId}/provider-modules/${providerId}?providerType=${providerType}`
    )
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(
        error.response?.data?.message || "Failed to remove support staff"
      );
    });
};

const updateAdditionalOfficers = async (
  enrollmentId: string,
  additionalOfficerIds: string[]
): Promise<void> => {
  return axios
    .patch(`/enrollments/${enrollmentId}`, {
      additionalOfficerId: additionalOfficerIds,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(
        error.response?.data?.message || "Failed to update additional officers"
      );
    });
};

const addGoal = async (
  enrollmentId: string,
  goal: CreateGoal
): Promise<void> => {
  return axios
    .post(`/enrollments/${enrollmentId}/goals`, goal)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(
        error.response?.data?.message ||
          "Failed to create the goal for the enrollment"
      );
    });
};

const listRedeemRequests = async (
  enrollmentId: string
): Promise<RedeemRequest[]> => {
  return axios
    .get(`/enrollments/${enrollmentId}/redeem-requests`)
    .then((res) => res.data.body)
    .catch((err) => {
      throw new Error(
        err.response?.data?.message || "Failed to fetch listRedeemRequests"
      );
    });
};

const getEnrollmentBalance = async (
  enrollmentId: string
): Promise<EnrollmentBalance> => {
  return axios
    .get(`/enrollments/${enrollmentId}/balance`)
    .then((res) => res.data.body)
    .catch((err) => {
      throw new Error(
        err.response?.data?.message || "Failed to get EnrollmentBalance"
      );
    });
};

const updateRedeemRequestIsViewed = async (
  enrollmentId: string,
  ids: string[]
): Promise<void> => {
  return axios
    .patch(`/enrollments/${enrollmentId}/redeem-requests/viewed`, { ids })
    .then(() => {})
    .catch((err) => {
      return Promise.reject(transformAxiosError(err));
    });
};

const approveRedeemRequest = async (
  enrollmentId: string,
  redeemRequestId: string,
  approvedBy: string
): Promise<void> => {
  return axios
    .patch(
      `/enrollments/${enrollmentId}/redeem-requests/${redeemRequestId}/approve`,
      { approvedBy }
    )
    .then(() => {})
    .catch((err) => {
      return Promise.reject(transformAxiosError(err));
    });
};

const rejectRedeemRequest = async (
  enrollmentId: string,
  redeemRequestId: string,
  rejectedBy: string,
  rejectedComment: string
): Promise<void> => {
  return axios
    .patch(
      `/enrollments/${enrollmentId}/redeem-requests/${redeemRequestId}/reject`,
      { rejectedBy, rejectedComment }
    )
    .then(() => {})
    .catch((err) => {
      return Promise.reject(transformAxiosError(err));
    });
};

const searchRedeemRequests = async ({
  enrollmentId,
  sortField,
  sortOrder,
  pageNumber,
  pageSize,
  status,
}: SearchRedeemRequestsPayload): Promise<SearchRedeemRequestsResponse> => {
  if (!enrollmentId) return { list: [], totalCount: 0 };
  const queryParams = new URLSearchParams({
    status: status.join(","),
    pageNumber: String(pageNumber + 1),
    pageSize: String(pageSize),
    sortField,
    sortOrder: sortOrder === 1 ? "asc" : "desc",
  });

  return axios
    .get(
      `/enrollments/${enrollmentId}/redeem-requests/search?${queryParams.toString()}`
    )
    .then((res) => {
      const body = res.data.body;
      return {
        list: body.list,
        totalCount: body.totalCount,
      };
    })
    .catch((err) => {
      return Promise.reject(transformAxiosError(err));
    });
};

const searchJournals = async ({
  enrollmentId,
  sortField,
  sortOrder,
  pageNumber,
  pageSize,
}: SearchJournalsPayload): Promise<SearchJournalResponse> => {
  if (!enrollmentId) return { list: [], totalCount: 0 };
  const queryParams = new URLSearchParams({
    pageNumber: String(pageNumber + 1),
    pageSize: String(pageSize),
    sortField,
    sortOrder: sortOrder === 1 ? "asc" : "desc",
  });

  return axios
    .get(
      `/enrollments/${enrollmentId}/journals/search?${queryParams.toString()}`
    )
    .then((res) => {
      const body = res.data.body;
      return {
        list: body.list,
        totalCount: body.totalCount,
      };
    })
    .catch((err) => {
      return Promise.reject(transformAxiosError(err));
    });
};

const updateJournal = async (
  enrollmentId: string,
  journalId: string,
  body: Partial<Journal>
): Promise<void> => {
  return axios
    .patch(`/enrollments/${enrollmentId}/journals/${journalId}`, body)
    .then(() => {})
    .catch((err) => {
      return Promise.reject(transformAxiosError(err));
    });
};

export const enrollmentService = {
  findById,
  updateEnrollment,
  updateProviderModules,
  updateSupportStaffModules,
  removeSupportStaff,
  updateAdditionalOfficers,
  addGoal,
  listRedeemRequests,
  getEnrollmentBalance,
  updateRedeemRequestIsViewed,
  approveRedeemRequest,
  rejectRedeemRequest,
  searchRedeemRequests,
  searchJournals,
  updateJournal,
};
