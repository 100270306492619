import { SetStateAction, useState } from "react";
import { DataTableCell, Order } from "./dataTable.interface";

export default function useTable(
  data: DataTableCell[],
  initialOrder: Order,
  initialOrderBy: keyof DataTableCell,
  initialPage: number
) {
  const [order, setOrder] = useState<Order>(initialOrder);
  const [orderBy, setOrderBy] = useState<keyof DataTableCell>(initialOrderBy);
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [page, setPage] = useState(initialPage);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof DataTableCell
  ) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";

    if (orderBy !== property || order !== newOrder) {
      setOrder(newOrder);
      setOrderBy(property);
    }
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.id);
      setSelected(newSelected as SetStateAction<readonly number[]>);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = () => {
    setPage(0);
  };

  return {
    order,
    orderBy,
    selected,
    page,
    handleRequestSort,
    handleSelectAllClick,
    handleClick,
    handleChangePage,
    handleChangeRowsPerPage,
  };
}
