import React, { useState, useEffect } from "react";
import { MessageModel } from "@chatscope/chat-ui-kit-react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { useNotificationStore } from "@/hooks/useUserNotifications";
import ContactList from "@/ui/content/chat/ContactList";
import ChatWindow from "@/ui/content/chat/ChatWindow";
import { Grid, useMediaQuery } from "@mui/material";
import theme from "@/utils/theme";

interface Contact {
  id: string;
  name: string;
  lastMessage: string;
  unread: boolean;
}

const ChatPage: React.FC = () => {
  const { addNotification, markNotificationAsRead } = useNotificationStore();
  const [selectedContact, setSelectedContact] = useState<string | null>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const initialContacts: Contact[] = [
    {
      id: "1",
      name: "Andrea Smith",
      lastMessage: "Let's work on it together.",
      unread: false,
    },
    {
      id: "2",
      name: "George Allan",
      lastMessage: "I'll help with your account.",
      unread: false,
    },
    {
      id: "3",
      name: "Miranda Lane",
      lastMessage: "I'll send you the update soon.",
      unread: false,
    },
  ];

  const [contacts, setContacts] = useState(initialContacts);

  const [contactMessages, setContactMessages] = useState<{
    [key: string]: MessageModel[];
  }>({
    "1": [
      {
        message: "Hello! How can I assist you today?",
        sentTime: "just now",
        sender: "Support",
        direction: "incoming",
        position: "single",
      },
    ],
    "2": [
      {
        message: "Hello, I can assist you with your account issues.",
        sentTime: "just now",
        sender: "Support",
        direction: "incoming",
        position: "single",
      },
    ],
    "3": [
      {
        message: "I’ll send you an update soon.",
        sentTime: "just now",
        sender: "Support",
        direction: "incoming",
        position: "single",
      },
    ],
  });

  const randomMessages = [
    "How are you doing?",
    "Is there anything else I can help you with?",
    "Don’t forget our meeting tomorrow!",
    "Please let me know if you have any questions.",
    "I’ll follow up with you later today.",
  ];

  const handleSelectContact = (contactId: string) => {
    setSelectedContact(contactId);
    markNotificationAsRead(contactId);
    setContacts((prevContacts) =>
      prevContacts.map((contact) =>
        contact.id === contactId ? { ...contact, unread: false } : contact
      )
    );
  };

  const handleSend = (message: string) => {
    if (!selectedContact) return;

    const newMessage: MessageModel = {
      message,
      sentTime: new Date().toLocaleTimeString(),
      sender: "User",
      direction: "outgoing",
      position: "single",
    };

    setContactMessages((prevMessages) => ({
      ...prevMessages,
      [selectedContact]: [...(prevMessages[selectedContact] || []), newMessage],
    }));
  };

  const selectedContactData =
    contacts.find((contact) => contact.id === selectedContact) || null;

  useEffect(() => {
    const interval = setInterval(() => {
      const randomContact =
        contacts[Math.floor(Math.random() * contacts.length)];
      const randomMessage =
        randomMessages[Math.floor(Math.random() * randomMessages.length)];
      const contactId = randomContact.id;

      setContactMessages((prevMessages) => ({
        ...prevMessages,
        [contactId]: [
          ...(prevMessages[contactId] || []),
          {
            message: randomMessage,
            sentTime: new Date().toLocaleTimeString(),
            sender: "Support",
            direction: "incoming",
            position: "single",
          },
        ],
      }));

      setContacts((prevContacts) =>
        prevContacts.map((contact) =>
          contact.id === contactId
            ? { ...contact, unread: true, lastMessage: randomMessage }
            : contact
        )
      );

      addNotification({
        id: contactId,
        message: randomMessage,
        isRead: false,
        createdAt: new Date(),
      });
    }, 10000);

    return () => clearInterval(interval);
  }, [contacts, randomMessages, addNotification]);

  return (
    <Grid container spacing={2.5}>
      <ContactList
        visible={selectedContact === null}
        isMobile={isMobile}
        selectedContactId={selectedContact}
        contacts={contacts}
        onSelectContact={handleSelectContact}
        contactMessages={contactMessages}
      />
      <ChatWindow
        isMobile={isMobile}
        selectedContactData={selectedContactData}
        contactMessages={contactMessages[selectedContact || ""]}
        onSend={handleSend}
        onGoBack={() => setSelectedContact(null)}
      />
    </Grid>
  );
};

export default ChatPage;
