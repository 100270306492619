import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Theme,
  CircularProgress,
} from "@mui/material";
import React, { FC, ReactNode } from "react";
import CloseIcon from "@mui/icons-material/Close";

export type ModalProps = {
  open: boolean;
  onClose: () => void;
  isPending: boolean;
  theme: Theme;
  title: string;
  content: ReactNode;
  actions?: ReactNode;
  size?: {
    width: string | number;
    minHeight: number;
    maxHeight: number;
    maxWidth: number;
  };
};

const Modal: FC<ModalProps> = ({
  open,
  onClose,
  isPending,
  theme,
  title,
  content,
  actions,
  size = {
    width: "100%",
    minHeight: 640,
    maxHeight: 720,
    maxWidth: 760,
  },
}) => {
  return (
    <Dialog
      onClose={() => {
        if (isPending) {
          return;
        }

        onClose();
      }}
      aria-labelledby="dialog-container"
      sx={{
        "& .MuiDialog-paper": {
          ...size,
          margin: {
            xs: "12px",
            sm: "12px",
          },
          maxHeight: {
            xs: "100%",
            sm: size.maxHeight,
          },
        },
      }}
      open={open}
      disableEscapeKeyDown={isPending}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2.5,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "60px",
        }}
        id="dialog-title"
        variant="headlineSmall"
        color={theme.palette.surface?.light || "#FFFFFF"}
        bgcolor={theme.palette.primary.dark || "#000000"}
      >
        {title}

        <IconButton
          aria-label="close"
          onClick={() => {
            if (isPending) {
              return;
            }

            onClose();
          }}
          sx={{
            color: theme.palette.surface?.light || "#FFFFFF",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          gap: "1.25rem",
          display: "flex",
          flexDirection: "column",
          padding: "1.25rem 1.25rem 0.75rem 1.25rem",
        }}
      >
        {content}
        {isPending && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 0.7)", // Semi-transparent overlay
              zIndex: 1,
            }}
          >
            <CircularProgress />
          </div>
        )}
      </DialogContent>
      {actions && (
        <DialogActions
          sx={{
            borderTop: "1px solid #ccc",
            p: "20px",
          }}
        >
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default Modal;
