import { create } from "zustand";
import { persist } from "zustand/middleware";

type Notification = {
  id: string;
  message: string;
  isRead: boolean;
  createdAt: Date;
};

type NotificationStore = {
  notifications: Notification[];
  addNotification: (notification: Notification) => void;
  markNotificationAsRead: (notificationId: string) => void;
  clearNotifications: () => void;
};

export const useNotificationStore = create(
  persist<NotificationStore>(
    (set) => ({
      notifications: [],

      addNotification: (notification) =>
        set((state) => ({
          notifications: [...state.notifications, notification],
        })),

      markNotificationAsRead: (notificationId) =>
        set((state) => ({
          notifications: state.notifications.map((n) =>
            n.id === notificationId ? { ...n, isRead: true } : n
          ),
        })),

      clearNotifications: () => set({ notifications: [] }),
    }),
    { name: "NotificationStore" }
  )
);
