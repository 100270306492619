import { ISettingResponse } from "../domain/setting.interface";
import { settingService } from "../services/setting.service";
import { useQuery } from "@tanstack/react-query";

const { findAll, findByName } = settingService;

export const useGetSettings = () => {
  const userQuery = useQuery({
    queryKey: ["get-setting-list"],
    queryFn: () => findAll(),
  });

  return userQuery;
};

export const useGetSettingByName = (settingName: string) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["get-setting-by-name", settingName],
    queryFn: () => findByName(settingName),
    staleTime: 10 * 1000, // 10 seconds in milliseconds
  });

  return {
    setting: data,
    isLoadingSetting: isLoading,
    refetchSetting: refetch,
  };
};

export const useCurrencySetting = ():
  | ISettingResponse
  | { mask: string; default: string } => {
  const currencySymbol = { USD: "$", EUR: "€", GBP: "£" };
  const { setting: currencySetting } = useGetSettingByName("currency");

  if (currencySetting) {
    // @ts-expect-error - the default value is a string
    currencySetting.mask = currencySymbol[currencySetting];
  }

  return currencySetting || { mask: "$", default: "USD" };
};

export const useIANATimeZoneSetting = (): string => {
  const { setting: timeZoneSetting } = useGetSettingByName("timeZone");
  return (timeZoneSetting?.timeZone as string) || "America/Michigan";
};

export const useCountrySetting = (): string => {
  const { setting: countrySetting } = useGetSettingByName("country");
  return (countrySetting?.country as string) || "US";
};

export const useDateOfBirthSetting = (): string => {
  const { setting: dateOfBirthSetting } = useGetSettingByName("dateOfBirth");
  return (dateOfBirthSetting?.dateOfBirth as string) || "mandatory";
};
